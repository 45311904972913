import environment from 'utility/environment'

let HTS_API_BASE  = 'https://api.hometaxshield.dev'
if(environment.production){
	HTS_API_BASE = 'https://api.hometaxshield.com'
}else if(environment.review){
	HTS_API_BASE = 'https://api.hometaxshield.review'
}

let VOCE_DATA_BASE = 'https://voce-data-linux-web-01-stage.azurewebsites.net'
if (environment.production) {
	VOCE_DATA_BASE = 'https://voce-data-linux-web-01.azurewebsites.net'
} else if (environment.review) { 
	VOCE_DATA_BASE = 'https://voce-data-linux-web-01-review.azurewebsites.net'
}

const GRIDS_BASE = environment.production
	? 'https://gridsapi.hometaxshield.com'
	: 'https://gridsapi.hometaxshield.dev'

export const WORDPRESS_BASE = 'https://wp.hometaxshield.com'
export const POST_ORDER_GRIDS = `${GRIDS_BASE}/CompGrid/OrderCompGrids`

export const GET_USER_ME = `${HTS_API_BASE}/user/me`
export const GET_USER = `${HTS_API_BASE}/user/GetUserProfileForSlugId/` // + user slugId
export const GET_USER_NOTIFICATIONS = `${HTS_API_BASE}/User/GetUserNotifications/` // + {userSlugId}
export const POST_USER_PROFILE = `${HTS_API_BASE}/User/UpdateUserProfile`
export const GET_USER_PROPERTIES = ({ userSlugId, year, tenant = 'hometaxshield' }) =>
	`${HTS_API_BASE}/User/GetUserPropsDetail/${userSlugId}${year ? `/${year}` : ''}/${tenant}`

export const GET_MEMBERSHIP_PRICE_DETAILS = ({ groupId, planId, tenant = 'hometaxshield' }) => 
	`${HTS_API_BASE}/HomeownerPlan/GetMembershipPriceDetails/${tenant}/${groupId}/${planId}`

export const GET_AUTHTOKEN = `${HTS_API_BASE}/Auth/GetAuthToken/`
export const POST_MAGICLINK = `${HTS_API_BASE}/Auth/SendMagicLink`
export const POST_CREATE_GROUP = `${HTS_API_BASE}/Group/CreateGroup`
export const GET_PROPERTY_GROUP_DETAILS = `${HTS_API_BASE}/Property/GetPropertyDetail`
export const GET_GROUP_DETAIL = `${HTS_API_BASE}/Group/GetGroupDetail`
export const GET_GROUP_INVOICES = `${HTS_API_BASE}/Invoice/GetGroupInvoices`
export const GET_INVOICE_HISTORY = `${HTS_API_BASE}/Invoice/GetBillingHistoryDetails/`
export const POST_GROUP_PROFILE = `${HTS_API_BASE}/Group/UpdateGroupProfile`

export const POST_VERIFY_PIDS = `${HTS_API_BASE}/GridOrder/VerifyGridOrderPids`
export const POST_SUBMIT_GRID_ORDER = `${HTS_API_BASE}/GridOrder/SubmitGridOrderDetails`
export const POST_GET_GRID_ORDER_DETAILS = `${HTS_API_BASE}/GridOrder/GetGridOrderDetails` // + {gridOrderId}
export const POST_GET_GRID_ORDER_PRICE = `${HTS_API_BASE}/GridOrder/GetGridOrderPriceDetails`

export const GET_PLANS = ({  tenant = 'hometaxshield', groupId = '' }) =>
	`${HTS_API_BASE}/HomeownerPlan/GetAvailableHomeownerPlans/${tenant}/${groupId}`

export const GET_GROUP_PAYMENT_METHODS = `${HTS_API_BASE}/Billing/GetGroupPaymentDetail` //AB656770/hometaxshield
export const POST_GROUP_DEFAULT_PAYMENT = `${HTS_API_BASE}/Billing/UpdateDefaultPayment`
export const GET_SETUP_INTENT_CLIENT_SECRET = `${HTS_API_BASE}/Billing/GetSetupIntentClientSecret`

export const POST_PAY_INVOICE = `${HTS_API_BASE}/Invoice/PaySuccessFeeInvoiceAmount`
export const POST_PAY_ACH_FAILED_INVOICE = `${HTS_API_BASE}/Invoice/PayAchFailedInvoiceAmount`
export const POST_PAY_RENEWAL = `${HTS_API_BASE}/Invoice/PayMembershipRenewalAmount`
export const POST_ADD_PROPERTIES = `${HTS_API_BASE}/Property/AddProperties`
export const GET_PROPERTY_DOCUMNETS = `${HTS_API_BASE}/Property/GetAttachments/`
export const POST_PROPERTY_ATTACHMENT = `${HTS_API_BASE}/Property/AddAttachment`
export const POST_CAN_CANCEL_PROPERTY = `${HTS_API_BASE}/Property/CanCancelSignupYearProperty`
export const POST_CANCEL_PROPERTY = `${HTS_API_BASE}/Property/CancelSignupYearProperty`
export const DELETE_PROPERTY_ATTACHMENT = `${HTS_API_BASE}/Property/DeleteAttachment/`
export const GET_TENANT_SETTINGS = `${HTS_API_BASE}/Tenant/GetSettings/` // + /tennant code
export const POST_UPGRADE_HOMEOWNER_PLAN = `${HTS_API_BASE}/HomeownerPlan/UpdateHomeownerPlan`

// OLD & NOT USED - CLEAN UP ANR REMOVE
export const GET_SEARCH_PROPERTY = `${HTS_API_BASE}/VoceHtsApi/PropertySearch/GetPropertySearchResults`

export const POST_PARTNER = `${HTS_API_BASE}/Partner/AddPartner`
export const GET_PARTNER_PUBLIC_INFO = `${HTS_API_BASE}/Partner/GetPartnerPublicInfo/` // expects /[slug]/hometaxshield
export const GET_PARTNER_FROM_ID = `${HTS_API_BASE}/Partner/GetPartnerForId/` // + 75FEAE1B-FF40-4B58-BCFA-B25DFC9A0B52
export const GET_PARTNER_FROM_SLUG = `${HTS_API_BASE}/Partner/GetPartnerForSlugName/`
export const GET_PARTNER_PROPERTIES = `${HTS_API_BASE}/Partner/GetPartnerProperties` // + 889C51C2/2021/hometaxshield
export const GET_GRID_PARTNER_FROM_SLUG = `${HTS_API_BASE}/Partner/GetCompGridPartnerForSlug/`
export const POST_PARTNER_UPDATE = `${HTS_API_BASE}/Partner/UpdatePartner`
export const GET_PARTNER_STATS = `${HTS_API_BASE}/Partner/GetPartnerStatistics`

export const POST_SEARCH_PROPERTY = `${VOCE_DATA_BASE}/api/v1/propertysearch`
export const GET_COUNTY = `${VOCE_DATA_BASE}/api/v1/countydata`
export const GET_COUNTIES = `${VOCE_DATA_BASE}/api/v1/countylist/tx`
export const GET_PROPERTY = `${VOCE_DATA_BASE}/api/v1/propertydata`
export const GET_STATE = `${VOCE_DATA_BASE}/api/v1/statedata`

// This is no longer used
const RE_AGENTS_BASE = environment.production
	? 'https://reagents.vocedata.com'
	: 'https://reagents.hometaxshield.dev'

// This no longer exists
export const GET_AGENT_FROM_TREK = `${RE_AGENTS_BASE}/VoceReAgentApi/RealEstateAgent/GetAgentForTrecNumber/`
export const POST_AGENT = `${RE_AGENTS_BASE}/VoceReAgentApi/RealEstateAgent/AddRealEstateAgent`
